@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
:root {
  --color-primary: #000;
  --color-green: #059669;
  --color-white: #FFF;
  --color-bg: #0f0f3e;
  --color-primarys: #6f6af8;
  --color-greens: #00c476;
  --color-primary-light: hsl(242, 91%, 69%, 18%);
  --color-primary-variant: #5854c7;
  --transition: all 300ms ease;
  --card-border-radius-1: 0.3rem;
  --card-border-radius-2: 0.5rem;
  --card-border-radius-3: 0.8rem;
  --card-border-radius-4: 2rem;
  --card-border-radius-5: 5rem;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  -webkit-appearance: 0;
     -moz-appearance: 0;
          appearance: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

.margin50 {
  margin: 50px 0;
}

.padding50 {
  padding: 40px 0;
}

.f20 {
  font-size: 20px;
}

.ms {
  margin-left: 35px;
}

.line1 {
  border-bottom: 6px solid var(--color-green);
  width: 100px;
  margin-bottom: 10px;
}

.borderTopWhite {
  border-top: 2px solid var(--color-white);
}

.line1- {
  border-bottom: 3px dashed var(--color-green);
  width: 100px;
  margin-bottom: 4px;
}

.line2 {
  border-bottom: 6px solid var(--color-primary);
  width: 100px;
  margin-bottom: 10px;
}

.green {
  color: var(--color-green);
}

.btnsGreen {
  display: inline-block;
  padding: 0.45em 1em;
  border: 1px solid var(--color-green);
  border-radius: 0.22em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: var(--color-green);
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.btnsGreen:hover {
  background-color: var(--color-green);
  color: var(--color-white);
}

.btnsWhite {
  display: inline-block;
  padding: 0.45em 1em;
  border: 1px solid var(--color-white);
  border-radius: 0.22em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: var(--color-white);
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.btnsWhite:hover {
  background-color: var(--color-primary);
  color: var(--color-green);
  border: none;
}

.btnsDark {
  display: inline-block;
  padding: 0.45em 1em;
  border-radius: 0.22em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: var(--color-primary);
  text-align: center;
  transition: all 0.2s;
  position: relative;
  background-color: var(--color-green);
}
.btnsDark:hover {
  color: var(--color-green);
  border: 1px solid var(--color-primary) !important;
  background-color: var(--color-primary);
  border: none;
}

.btnsDeepGreen {
  display: inline-block;
  padding: 0.45em 1em;
  border-radius: 0.35em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  background-color: var(--color-green);
  color: var(--color-white);
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.btnsDeepGreen:hover {
  background-color: var(--color-greens);
  color: var(--color-white);
}

.Getstartedmodal h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  color: var(--color-green);
}
.Getstartedmodal .diff {
  padding-top: 10px;
}
.Getstartedmodal .diff button {
  font-weight: 600;
}

.LinkNav {
  background-color: var(--color-green);
  color: var(--color-white);
  padding: 5px 20px;
}
.LinkNav ul {
  display: flex;
  align-items: center;
  margin: 0px;
}
.LinkNav ul li {
  margin: 10px;
}

.border1 {
  border-right: 3px solid var(--color-green);
  border-bottom: 3px solid var(--color-green);
}

.border3 {
  border-right: 3px solid var(--color-green);
}

.border2 {
  border-left: 3px solid var(--color-green);
  border-bottom: 3px solid var(--color-green);
}

.ChangeButton {
  position: relative;
  padding: 1rem 2rem 0.5rem 2.5rem;
  color: #000000;
  border: 3px solid #776e62;
  transition: padding 0.3s ease-in-out;
}

.ChangeButton:before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: -1;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border-right: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  transition: all 0.3s ease-in-out;
}

.ChangeButton:hover {
  padding: 0.75rem 2.25rem;
}

.ChangeButton:hover:before {
  top: 0;
  left: 0;
}

.upper,
.down,
.side {
  padding: 10px 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  margin: 10px;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.upper {
  border: 2px solid rgb(255, 255, 255);
  z-index: 1;
  color: white;
}

.upper:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

.upper:hover {
  color: var(--color-green);
}

.upper:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}

.Networkdiv {
  width: 150px;
  border-radius: 10px;
  background-color: #fff;
  height: 85px;
  margin: 10px;
  padding: 15px;
  -o-object-position: center;
     object-position: center;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.Networkdiv img {
  max-height: 60px;
}

.side {
  border: 2px solid rgb(255, 255, 255);
  z-index: 1;
  color: white;
}

.side:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

.side:hover {
  color: var(--color-green);
}

.side:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.side:active {
  top: 2px;
}

.down {
  border: 2px solid rgb(255, 255, 255);
  z-index: 1;
  color: white;
}

.down:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

.down:hover {
  color: var(--color-green);
}

.down:hover:after {
  top: 0;
  height: 100%;
}

.down:active {
  top: 2px;
}

.Banner1 {
  margin: 60px 0;
}
.Banner1 h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  font-size: 65px;
}
.Banner1 h1 span {
  font-weight: 400;
}
.Banner1 p {
  font-size: 20px;
}
.Banner1 p span {
  color: var(--color-green);
}
.Banner1 img {
  width: 100%;
}
.Banner1 .ms {
  margin: 0 !important;
}

.Scale {
  background-color: var(--color-primary);
  padding: 30px 0;
}
.Scale h1 {
  color: var(--color-green);
  font-size: 40px;
}
.Scale p {
  color: var(--color-white);
  font-size: 20px;
}

.Why {
  margin: 50px 0;
}
.Why .header h2 {
  line-height: normal;
  font-size: 30px;
  color: var(--color-green);
}
.Why .header p {
  font-size: 20px;
}

.Tab {
  margin: 40px 0;
}
.Tab h2 {
  line-height: normal;
}
.Tab .tab {
  border-top: 1px solid #ddd;
  padding-top: 35px;
  cursor: pointer;
}
.Tab .tab button {
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 3px 10px;
  font-size: 13px;
  display: none;
}
.Tab .tab:hover {
  border-top: 1px solid var(--color-green);
}
.Tab .tab:hover button {
  display: block;
}
.Tab .tab:hover button:hover {
  background-color: var(--color-green);
  color: var(--color-white);
  border: 2px solid var(--color-green);
}
.Tab img {
  width: 100%;
}

.HomeBusiness {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 50px 0;
}
.HomeBusiness .quote {
  font-size: 40px;
  font-weight: 200;
  font-family: "Roboto condensed";
}
.HomeBusiness .quote span {
  font-weight: 900;
  color: var(--color-green);
}
.HomeBusiness h2 {
  line-height: 60px;
  color: var(--color-green);
  font-size: 60px;
}
.HomeBusiness p {
  font-size: 27px;
}
.HomeBusiness button {
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 16px;
}
.HomeBusiness button:hover {
  background-color: var(--color-green);
  color: var(--color-white);
  border: 2px solid var(--color-green);
}
.HomeBusiness img {
  width: 100%;
}

.InviteToNetwork {
  background-color: var(--color-primary);
  padding: 50px 0;
}
.InviteToNetwork .left {
  padding: 25px;
}
.InviteToNetwork .left p {
  font-size: 15px;
  margin: 0;
}
.InviteToNetwork .right {
  padding: 20px 0;
}
.InviteToNetwork .right p {
  font-size: 20px;
  margin: 0;
  color: var(--color-white);
}

.CarouselHome .carousel div {
  box-shadow: none !important;
  color: var(--color-primary);
}
.CarouselHome .carousel-inner {
  background: #F4FCFA;
}
.CarouselHome .carousel-inner .container {
  height: 400px;
  padding: 50px 0;
}
.CarouselHome .carousel-inner .pt-0 {
  color: rgba(7, 16, 25, 0.5019607843);
}
.CarouselHome .carousel-inner .col-md-10 img {
  width: 40px;
}
.CarouselHome .carousel-inner .col-md-10 p {
  font-size: 22px;
  font-weight: 500;
}
.CarouselHome .carousel-inner img {
  width: 50px;
}

.Visitblog {
  padding: 50px 0;
}
.Visitblog .row {
  border-bottom: 1px solid #ddd;
}
.Visitblog h1 {
  font-size: 30px;
  margin-bottom: 30px;
}
.Visitblog .imgdiv {
  position: relative;
}
.Visitblog .imgdiv img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.Visitblog .imgdiv span {
  position: absolute;
  bottom: 15px;
  left: 15px;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 10px;
  background-color: var(--color-white);
}

.PathfinderHome {
  padding: 30px 0;
}
.PathfinderHome h2 {
  line-height: normal;
  font-size: 40px;
  color: var(--color-green);
}
.PathfinderHome img {
  width: 150px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 150px;
}
.PathfinderHome .text p {
  font-size: 15px;
}

.parners {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.parners div {
  margin: 0 25px;
}

.NewFooter {
  background-color: var(--color-primary);
  padding-top: 50px;
  padding-bottom: 10px;
  color: var(--color-white);
}
.NewFooter img {
  width: 250px;
}
.NewFooter .join {
  font-size: 20px;
  margin: 15px 0;
}
.NewFooter .social {
  display: flex;
  align-items: center;
}
.NewFooter .social svg {
  margin: 0 10px;
  color: var(--color-green);
}
.NewFooter ul {
  margin: 0;
  padding: 0;
}
.NewFooter ul li {
  margin: 10px;
}
.NewFooter span {
  color: var(--color-green);
  font-size: 12px;
}

.values h1 {
  font-size: 30px;
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: var(--color-green);
}
.values .cardd {
  cursor: pointer;
  position: relative;
  transition: var(--transition);
}
.values .cardd:hover p {
  transition: var(--transition);
  display: block !important;
}
.values .cardd .text {
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7882352941), rgba(0, 0, 0, 0.9764705882));
  bottom: 0px;
  padding: 15px;
  width: 100%;
}
.values .cardd .text .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.values .cardd .text .flex svg {
  color: var(--color-green);
}
.values .cardd .text h2 {
  line-height: normal;
  font-size: 25px;
  margin-bottom: 5px;
  color: var(--color-green);
}
.values .cardd .text p {
  color: var(--color-white);
  display: none;
}

.carousel {
  color: var(--color-white);
  padding: 5px;
  border-radius: var(--card-border-radius-2);
}
.carousel div {
  border-radius: var(--card-border-radius-2);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.carousel h2 {
  line-height: normal;
  font-size: 20px !important;
  background-color: var(--color-primary);
  padding: 10px;
  border-radius: var(--card-border-radius-2) var(--card-border-radius-2) 0 0;
  font-weight: 700 !important;
}
.carousel p {
  padding: 15px;
}

nav {
  border-bottom: none !important;
}

.navbar {
  background-color: var(--color-white);
  border-bottom: none !important;
  padding: 10px;
  border: none !important;
  --bs-navbar-toggler-border-color: none !important;
}
.navbar .logo {
  width: 200px;
}
.navbar span {
  color: var(--color-primary);
  margin: 5px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.navbar span:hover {
  color: var(--color-green);
}
.navbar li {
  font-size: 17px;
  margin: 8px 0;
}
.navbar .dropdown-menu {
  padding: 0 !important;
  width: 200px;
  margin: 0 !important;
  background: #efefef !important;
  border: none;
  border-radius: var(--card-border-radius-2);
}
.navbar .dropdown-menu .dropdown-item {
  padding: 10px 20px !important;
  margin: 0 !important;
}
.navbar .dropdown-menu .dropdown-item:hover {
  background-color: #06855d !important;
  color: var(--color-white) !important;
  transition: var(--transition);
}

.banner1 {
  background-image: url(../assets/images/solutions/pexels-michelangelo-buonarroti-8728388.jpg);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}
.banner1 .text {
  position: absolute;
  left: 7%;
  width: 500px;
  bottom: 35%;
}
.banner1 .text h2 {
  font-size: 45px;
  line-height: 46px;
  color: var(--color-primary);
  font-weight: 600;
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.banner1 .arrow {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 15%;
}

.banner2 {
  background-image: url(../assets/images/solutions/pexels-google-deepmind-17485678.jpg);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.banner2 .text {
  position: absolute;
  right: 3%;
  width: 500px;
  bottom: 35%;
}
.banner2 .text h2 {
  font-size: 45px;
  line-height: 46px;
  color: var(--color-primary);
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.banner2 .arrow {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 10%;
}

.banner3 {
  background-image: url(../assets/images/solutions/pexels-google-deepmind-17483868.jpg);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.banner3 .text {
  position: absolute;
  left: 5%;
  width: 400px;
  top: 12%;
}
.banner3 .text h2 {
  font-size: 40px;
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 40px;
}
.banner3 .arrow {
  position: absolute;
  left: 50%;
  bottom: 15%;
}
.banner3 .arrow svg {
  color: var(--color-primary);
}

.banner1 .arrow img,
.banner2 .arrow img,
.banner3 .arrow img {
  height: 56px;
  cursor: pointer;
}

.section1 h2 {
  font-size: 35px;
  line-height: 40px;
}

.section2 {
  background-color: var(--color-primary);
  padding: 30px 0;
}
.section2 h2 {
  font-size: 30px;
  color: var(--color-green);
  line-height: normal;
}
.section2 img {
  border-radius: 20px 0 20px 0;
}
.section2 .text {
  color: var(--color-white);
  padding: 10px;
  border-radius: 0 0 10px 10px;
}
.section2 .text h2 {
  color: var(--color-green);
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

.section3 {
  background-color: var(--color-primary);
  padding: 50px 0;
}
.section3 .head {
  padding: 20px;
  background-color: var(--color-green);
}
.section3 .head h2 {
  font-size: 25px;
  color: var(--color-white);
  line-height: normal;
}
.section3 button {
  padding: 0 !important;
}
.section3 .accordion-button:not(.collapsed) {
  color: var(--color-green) !important;
  background-color: var(--color-primary);
  line-height: normal;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.section3 .accordion-button:focus {
  border-color: gray;
  box-shadow: grey;
}
.section3 .accordion-button {
  line-height: normal;
  padding: 20px !important;
  color: var(--color-green);
  background-color: var(--color-primary);
}
.section3 .accordion-body {
  background-color: var(--color-green);
}

.section4 {
  background-color: var(--color-green);
}
.section4 .first {
  padding: 13%;
  background-image: url(../assets/images/solutions/pexels-pixabay-373543.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.section4 .first h2 {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.5176470588);
  color: var(--color-white);
  line-height: normal;
  font-size: 25px;
}
.section4 .second {
  margin-top: 40px;
  padding: 10px;
}
.section4 .second h2 {
  font-size: 25px;
  color: var(--color-white);
  line-height: normal;
}

.contactPage .cont {
  background-color: var(--color-primary);
  color: var(--color-primary);
  padding: 30px;
}

.contact h2 {
  line-height: normal;
  font-size: 30px;
  color: var(--color-green);
}
.contact button {
  background-color: var(--color-green);
  color: var(--color-primary);
  padding: 10px 25px;
  border-radius: 10px;
  font-weight: 600;
  transition: var(--transition);
  cursor: pointer;
}
.contact button:hover {
  background-color: #06855d;
}

.about .bannerr1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.8352941176)), url(/src/assets/images/about/pexels-tara-winstead-8386422.jpg);
  height: 30vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.about .bannerr1 h2 {
  text-align: center;
  line-height: normal;
  font-size: 50px;
  font-weight: 700;
  padding-top: 70px;
  color: var(--color-green);
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.about .sectionn1 h2 {
  color: var(--color-green);
  font-size: 30px;
  line-height: normal;
  margin: 20px 0;
}
.about .sectionn1 h1 {
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  color: var(--color-white);
  padding: 5px 10px;
}
.about .sectionn1 .who {
  background-color: var(--color-primary);
}
.about .sectionn1 .who h2 {
  color: var(--color-green);
  font-size: 20px;
}
.about .sectionn1 .who p {
  color: var(--color-white);
}
.about .sectionn1 .img3 {
  background-image: url(/src/assets/images/about/pexels-sierra-koder-10264752.jpg);
  height: 100%;
  background-size: cover;
  background-position: center;
}
.about .sectionn2 h2 {
  color: var(--color-white);
  font-size: 35px;
  line-height: normal;
}
.about .sectionn2 .one {
  background-color: var(--color-green);
  text-align: center;
}
.about .sectionn3 {
  background-color: var(--color-primary);
  padding: 30px 0;
}
.about .sectionn3 .mb-5 p {
  font-size: 20px;
  text-align: center;
}
.about .sectionn3 .opp {
  color: var(--color-green);
  font-size: 25px;
  text-align: center;
  line-height: normal;
}
.about .sectionn3 .list h2 {
  color: var(--color-green);
  font-size: 20px;
  line-height: normal;
  margin-bottom: 10px;
}
.about .sectionn3 p {
  color: var(--color-white);
}
.about .sectionn3 .one {
  text-align: center;
}

.JudgeAuth {
  min-height: 100vh;
}
.JudgeAuth .login {
  border: 1px solid #ddd;
  background-color: #efefef;
}
.JudgeAuth .login .form-check {
  display: flex;
  align-items: center;
}

.Apply {
  min-height: 100vh;
}
.Apply .Header {
  background-color: var(--color-green);
  padding: 15px;
  color: white;
  margin-bottom: 30px;
}
.Apply .judgeSide {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}
.Apply .judgeSide .span {
  padding: 15px;
  background: #efefef;
  width: 100%;
  border-radius: 10px 10px 0 0;
  border: none;
}
.Apply .judgeSide ul {
  padding: 0;
  margin: 0;
}
.Apply .judgeSide ul li {
  padding: 15px;
  display: flex;
  align-items: center;
  border-top: 1px solid #efefef;
}
.Apply .judgeSide ul li svg {
  margin-right: 10px;
}
.Apply .judgeSide ul li:hover {
  background-color: #efefef;
  cursor: pointer;
}

.connected h2 {
  color: var(--color-green);
  font-size: 35px;
  line-height: normal;
  margin-bottom: 10px;
}

.connected2 {
  background-color: var(--color-green);
  border-radius: 30px;
  margin: 50px 0;
  color: var(--color-white);
  padding: 20px;
}
.connected2 h2 {
  color: var(--color-white);
  font-size: 35px;
  line-height: normal;
  margin-bottom: 10px;
}

.pathfinders .bannerr1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.8352941176)), url(/src/assets/images/pathfinder/pexels-kampus-production-5940841.jpg);
  height: 35vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.pathfinders .bannerr1 p {
  text-align: center;
  color: var(--color-white);
  font-size: 20px !important;
}
.pathfinders .bannerr1 span {
  padding: 0 10px;
  color: var(--color-white);
}
.pathfinders .bannerr1 h2 {
  text-align: center;
  line-height: normal;
  font-size: 50px;
  font-weight: 700;
  padding-top: 60px;
  color: var(--color-green);
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.pathfinders .section11 h2 {
  color: var(--color-green);
  font-size: 30px;
  line-height: normal;
}
.pathfinders .section12 {
  background-color: var(--color-green);
  padding: 30px 0;
}
.pathfinders .section12 .text {
  text-align: center;
}
.pathfinders .section12 h2 {
  color: var(--color-white);
  font-size: 30px;
  line-height: normal;
  text-align: center;
}
.pathfinders .section12 .apply {
  display: inline-block;
  padding: 0.65em 1.6em;
  border: 3px solid #ffffff;
  margin: 15px 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.pathfinders .section12 .apply:hover {
  background-color: white;
  color: #000000;
}
.pathfinders .section13 {
  padding: 40px 0;
}
.pathfinders .section13 .text {
  text-align: center;
}
.pathfinders .section13 .text h2 {
  color: var(--color-green);
  font-size: 30px;
  line-height: normal;
  text-align: center;
}
.pathfinders .section13 h2 {
  color: var(--color-green);
  font-size: 20px;
  line-height: normal;
  margin-bottom: 5px;
}
.pathfinders .section13 .row div {
  margin-bottom: 10px;
}
.pathfinders .section13 .apply {
  display: inline-block;
  padding: 0.65em 1.6em;
  border: 3px solid var(--color-green);
  margin: 15px 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: var(--color-green);
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.pathfinders .section13 .apply:hover {
  background-color: var(--color-green);
  color: #000000;
}
.pathfinders .section14 {
  background-color: var(--color-primary);
  padding: 40px 0;
  color: var(--color-white);
}
.pathfinders .section14 .accordion-button:not(.collapsed) {
  color: var(--color-green) !important;
  background-color: var(--color-primary);
  line-height: normal;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.pathfinders .section14 span {
  color: var(--color-green);
  font-weight: 600;
}
.pathfinders .section14 .accordion-button:focus {
  border-color: gray;
  box-shadow: grey;
}
.pathfinders .section14 .accordion-button {
  line-height: normal;
  padding: 20px !important;
  color: var(--color-green);
  background-color: var(--color-primary);
}
.pathfinders .section14 .accordion-body {
  background-color: var(--color-green);
}
.pathfinders .section14 .accordion-body p {
  color: var(--color-white);
  font-weight: 600;
  margin-bottom: 10px;
}
.pathfinders .section14 .accordion-body ul li {
  list-style: inside;
}
.pathfinders .section14 h2 {
  color: var(--color-green);
  font-size: 20px;
  line-height: normal;
}
.pathfinders .section14 .row div {
  margin-bottom: 10px;
}
.pathfinders .section14 .apply {
  display: inline-block;
  padding: 0.65em 1.6em;
  border: 3px solid var(--color-green);
  margin: 15px 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: var(--color-green);
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.pathfinders .section14 .apply:hover {
  background-color: var(--color-green);
  color: #000000;
}

.judgebanner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.684), rgba(0, 0, 0, 0.8352941176)), url(/src/assets/images/judge/pexels-towfiqu-barbhuiya-3440682-11505601.jpg);
  height: 30vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.judgebanner p {
  text-align: center;
  color: var(--color-white);
  font-size: 20px !important;
}
.judgebanner span {
  padding: 0 10px;
  color: var(--color-white);
}
.judgebanner h2 {
  text-align: center;
  line-height: normal;
  font-size: 50px;
  font-weight: 700;
  padding-top: 60px;
  color: var(--color-green);
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}

.greencard {
  background-color: rgba(0, 0, 0, 0.0705882353);
  padding: 20px;
}
.greencard h2 {
  font-size: 25px !important;
}

.awardsection h2 {
  font-size: 30px;
  color: var(--color-green);
}
.awardsection img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.white {
  color: white;
}

.partners .bannerr1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.684), rgba(0, 0, 0, 0.8352941176)), url(/src/assets/images/partners/pexels-ketut-subiyanto-4963436.jpg);
  height: 30vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.partners .bannerr1 p {
  text-align: center;
  color: var(--color-white);
  font-size: 20px !important;
}
.partners .bannerr1 span {
  padding: 0 10px;
  color: var(--color-white);
}
.partners .bannerr1 h2 {
  text-align: center;
  line-height: normal;
  font-size: 50px;
  font-weight: 700;
  padding-top: 60px;
  color: var(--color-green);
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.partners .secttion1 h2 {
  color: var(--color-green);
  font-size: 30px;
  line-height: normal;
}
.partners .secttion2 h1 {
  color: var(--color-green);
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.partners .secttion2 .text {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.partners .secttion2 .text span {
  margin: 10px 0;
}
.partners .secttion2 .text span img {
  width: 50%;
  margin-left: 40px;
}
.partners .secttion2 .text h2 {
  color: var(--color-green);
  font-size: 22px;
  line-height: normal;
}
.partners .secttion3 {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 30px 0;
}
.partners .secttion3 h1 {
  color: var(--color-green);
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.partners .secttion3 p {
  font-size: 20px;
  text-align: center;
}
.partners .secttion3 .type1,
.partners .secttion3 .type2,
.partners .secttion3 .type3,
.partners .secttion3 .type4,
.partners .secttion3 .type5 {
  border-radius: var(--card-border-radius-2);
  margin-bottom: 10px;
}
.partners .secttion3 .type1 h2,
.partners .secttion3 .type2 h2,
.partners .secttion3 .type3 h2,
.partners .secttion3 .type4 h2,
.partners .secttion3 .type5 h2 {
  font-size: 22px;
  color: var(--color-white);
  line-height: normal;
  padding: 10px 20px;
  border-radius: var(--card-border-radius-2) var(--card-border-radius-2) 0 0;
  background-color: var(--color-green);
}
.partners .secttion3 .type1 p,
.partners .secttion3 .type2 p,
.partners .secttion3 .type3 p,
.partners .secttion3 .type4 p,
.partners .secttion3 .type5 p {
  padding: 15px;
  text-align: left;
  color: var(--color-white);
}
.partners .secttion3 .type1 {
  color: var(--color-primarys);
  border: 2px solid var(--color-primarys);
}
.partners .secttion3 .type1 h2 {
  background-color: var(--color-primarys);
}
.partners .secttion3 .type2 {
  color: var(--color-greens);
  border: 2px solid var(--color-greens);
}
.partners .secttion3 .type2 h2 {
  background-color: var(--color-greens);
}
.partners .secttion3 .type3 {
  color: #f5dd42;
  border: 2px solid #f5dd42;
}
.partners .secttion3 .type3 h2 {
  background-color: #f5dd42;
}
.partners .secttion3 .type4 {
  color: #f56942;
  border: 2px solid #f56942;
}
.partners .secttion3 .type4 h2 {
  background-color: #f56942;
}
.partners .secttion3 .type5 {
  color: #42eff5;
  border: 2px solid #42eff5;
}
.partners .secttion3 .type5 h2 {
  background-color: #42eff5;
}
.partners .secttion4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6352941176), rgba(0, 0, 0, 0.6784313725)), url(../assets/images/partners/img.png);
  background-position: center;
  background-size: contain;
  padding: 40px;
  height: 50vh;
}
.partners .secttion4 .text {
  background-color: rgba(5, 150, 104, 0.5411764706);
  padding: 20px;
  color: white;
}
.partners .secttion4 .text p {
  font-size: 25px;
  font-family: "Oswald", sans-serif;
}
.partners .secttion5 {
  background-color: var(--color-green);
}
.partners .secttion5 .first {
  padding: 8%;
  background-image: url(../assets/images/partners/pexels-kindel-media-7688156.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.partners .secttion5 .first h2 {
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.5176470588);
  color: var(--color-white);
  line-height: normal;
  font-size: 22px;
}
.partners .secttion5 .second {
  margin-top: 40px;
  padding: 10px;
}
.partners .secttion5 .second h2 {
  font-size: 25px;
  color: var(--color-white);
  line-height: normal;
}
.partners .secttion5 .apply {
  display: inline-block;
  padding: 0.65em 1.6em;
  border: 3px solid var(--color-primary);
  margin: 15px 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: var(--color-primary);
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.partners .secttion5 .apply:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.security .bannerr1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.8352941176)), url(/src/assets/images/security/pexels-pixabay-60504.jpg);
  height: 35vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.security .bannerr1 p {
  text-align: center;
  color: var(--color-white);
  font-size: 20px !important;
}
.security .bannerr1 span {
  padding: 0 10px;
  color: var(--color-white);
}
.security .bannerr1 h2 {
  text-align: center;
  line-height: normal;
  font-size: 50px;
  font-weight: 700;
  padding-top: 60px;
  color: var(--color-green);
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.security .sectioon1 .path {
  font-size: 20px;
  text-align: left;
}
.security .sectioon1 .text {
  padding: 20px;
}
.security .sectioon1 .text h2 {
  line-height: normal;
  font-size: 18px;
  color: var(--color-greens);
}
.security .sectioon1 .text h1 {
  font-family: "Oswald", sans-serif;
  font-size: 25px;
}
.security .sectioon1 .text ul {
  margin-bottom: 25px;
}
.security .sectioon1 .text ul li {
  margin-bottom: 10px;
}
.security .sectioon1 .text ul li p {
  margin-left: 15px;
}
.security .sectioon1 .role h2 {
  line-height: normal;
  font-size: 23px;
  margin-bottom: 10px;
  color: var(--color-greens);
}
.security .sectioon1 .role ul li {
  list-style: inside;
}
.security .sectioon1 .tab {
  background-color: var(--color-primary);
  padding: 30px 0;
  color: var(--color-white);
}
.security .sectioon1 .tab h2 {
  line-height: normal;
  font-size: 23px;
  margin-bottom: 10px;
  color: var(--color-greens);
}
.security .sectioon2 {
  background-color: var(--color-green);
}
.security .sectioon2 .first {
  padding: 8%;
  background-image: url(../assets/images/security/pexels-jerome-dominici-612266.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.security .sectioon2 .first h2 {
  color: var(--color-primary);
  line-height: normal;
  font-size: 35px;
  margin-left: 10px;
}
.security .sectioon2 .first .light {
  padding: 25px;
  background-color: rgba(251, 249, 249, 0.5176470588);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.security .sectioon2 .first .light img {
  width: 50px;
}
.security .sectioon2 .second {
  margin-top: 20px;
  padding: 10px;
}
.security .sectioon2 .second h2 {
  font-size: 25px;
  color: var(--color-white);
  line-height: normal;
}
.security .sectioon2 .apply {
  display: inline-block;
  padding: 0.65em 1.6em;
  border: 3px solid var(--color-primary);
  margin: 15px 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: var(--color-primary);
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.security .sectioon2 .apply:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.faqs .bannerr1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.713), rgba(0, 0, 0, 0.8352941176)), url(/src/assets/images/faqs/pexels-leeloo-thefirst-5428829.jpg);
  height: 35vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.faqs .bannerr1 p {
  text-align: center;
  color: var(--color-white);
  font-size: 20px !important;
}
.faqs .bannerr1 span {
  padding: 0 10px;
  color: var(--color-white);
}
.faqs .bannerr1 h2 {
  text-align: center;
  line-height: normal;
  font-size: 50px;
  font-weight: 700;
  padding-top: 70px;
  color: var(--color-green);
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.faqs .ssection1 h2 {
  line-height: normal;
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--color-green);
}
.faqs .accordion-button:not(.collapsed) {
  color: var(--color-green) !important;
  line-height: normal;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 white;
}
.faqs .accordion-button:focus {
  border-color: gray;
  box-shadow: grey;
}
.faqs .accordion-header {
  margin: 0 !important;
}
.faqs .accordion-button {
  line-height: normal;
  padding: 20px !important;
  color: var(--color-green);
}
.faqs .accordion-body {
  background-color: rgba(239, 239, 239, 0.3725490196);
}
.faqs .accordion-body p {
  color: var(--color-primary);
}
.faqs .accordion-button:not(.collapsed) {
  background-color: white !important;
}
.faqs .ssection3 {
  background-color: var(--color-green);
  padding: 20px 0;
}
.faqs .ssection3 h1 {
  font-size: 40px;
  color: var(--color-white);
  font-weight: 700;
}

.getintouch .ready p {
  font-size: 20px;
  margin-bottom: 30px;
}
.getintouch .bannerr1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.713), rgba(0, 0, 0, 0.8352941176)), url(/src/assets/images/Get-in-touch/pexels-adrienn-1458283.jpg);
  height: 40vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.getintouch .bannerr1 p {
  text-align: center;
  color: var(--color-white);
  font-size: 20px !important;
}
.getintouch .bannerr1 span {
  padding: 0 10px;
  color: var(--color-white);
}
.getintouch .bannerr1 h2 {
  text-align: center;
  line-height: normal;
  font-size: 50px;
  font-weight: 700;
  padding-top: 60px;
  color: var(--color-green);
  font-family: "Bebas Neue", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.getintouch .bannerr1 .flex {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.getintouch .seection1 h2 {
  line-height: normal;
  font-size: 30px;
  margin-bottom: 20px;
  color: var(--color-green);
  text-align: center;
}
.getintouch .seection1 .form-check-label {
  font-size: 14px;
}
.getintouch .seection1 .form-check-label span {
  color: blue;
}
.getintouch .seection2 {
  background-color: var(--color-primary);
  padding: 30px 0;
}
.getintouch .seection2 h2 {
  line-height: normal;
  font-size: 30px;
  margin-bottom: 20px;
  color: var(--color-green);
  text-align: center;
}
.getintouch .seection2 p,
.getintouch .seection2 h1 {
  color: var(--color-white);
}
.getintouch .seection2 .social {
  margin: 20px 0;
}
.getintouch .seection2 .social h1 {
  font-size: 20px;
}
.getintouch .seection2 .social .innit {
  margin: 10px 0;
}
.getintouch .seection2 .social .innit svg {
  border-radius: 50%;
  width: 100px;
  color: var(--color-green);
  padding: 15px;
  height: 100px;
}
.getintouch .seection3 {
  background-color: var(--color-primary);
  padding: 30px 0;
}
.getintouch .seection3 h2 {
  line-height: normal;
  font-size: 30px;
  margin-bottom: 20px;
  color: var(--color-green);
}
.getintouch .seection3 p {
  color: var(--color-green);
}
.getintouch .seection3 button {
  background-color: var(--color-green);
  border-radius: 5px;
  padding: 7px 25px;
  margin: 20px 0;
}
.getintouch .seection3 button svg {
  color: var(--color-primary);
}
.getintouch .cont {
  background-color: var(--color-green);
  padding: 30px 0;
  color: var(--color-primary);
}
.getintouch .cont h2 {
  line-height: normal;
  font-size: 40px;
  margin-bottom: 10px;
  color: var(--color-primary);
}
.getintouch .cont button {
  display: inline-block;
  padding: 0.45em 1em;
  border: 1px solid var(--color-white);
  border-radius: 0.22em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: var(--color-white);
  text-align: center;
  transition: all 0.2s;
  animation: bn13bouncy 5s infinite linear;
  position: relative;
}
.getintouch .cont button:hover {
  background-color: var(--color-primary);
  color: var(--color-green);
  border: none;
}
.getintouch .cont .ms {
  margin: 0 !important;
}
.getintouch .cont .heard {
  text-align: right;
  padding-top: 30px;
}
.getintouch .seection4 {
  padding: 20px 0;
  background-color: var(--color-primary);
  color: var(--color-white);
}
.getintouch .seection4 .thank h2 {
  line-height: normal;
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--color-green);
}
.getintouch .seection4 .thank p {
  font-size: 20px;
}

.eventPage .events {
  border: 1px solid rgba(224, 224, 224, 0.7);
  background: #f8f8f8;
  border-radius: 30px;
}
.eventPage .events ul {
  background-color: var(--color-green);
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.eventPage .events ul li {
  display: flex;
  align-items: center;
}
.eventPage .events p {
  font-size: 16px;
}
.eventPage .events img {
  width: 100%;
  border-radius: 30px;
}
.eventPage .events svg {
  margin-right: 10px;
}
.eventPage .highlights li {
  margin: 20px 0;
}
.eventPage .highlights li span {
  font-size: 30px;
  font-weight: 800;
}

@media only screen and (max-width: 600px) {
  .padding50 {
    padding: 45px 0 !important;
  }
  .Banner1 {
    margin: 30px 0 !important;
  }
  .Banner1 h1 {
    font-size: 35px !important;
  }
  .Banner1 p {
    font-size: 14px !important;
  }
  .banner1 .text,
  .banner2 .text,
  .banner3 .text {
    width: 290px !important;
  }
  .banner1 .text h2,
  .banner2 .text h2,
  .banner3 .text h2 {
    font-size: 27px !important;
  }
  .LinkNav {
    padding: 5px 15px !important;
  }
  .LinkNav ul {
    display: flex;
    align-items: center;
    padding: 0px !important;
  }
  .LinkNav ul li {
    margin: 10px;
    font-size: 15px !important;
  }
  .getintouch .flex {
    margin-top: 10px !important;
  }
  .contact .Contactimage {
    display: none !important;
  }
  .pathfinders .bannerr1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.8352941176)), url(/src/assets/images/pathfinder/pexels-kampus-production-5940841.jpg);
    height: 35vh;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  .pathfinders .bannerr1 p {
    font-size: 15px !important;
  }
  .pathfinders .bannerr1 h2 {
    text-align: center;
    line-height: normal;
    font-size: 30px !important;
    font-weight: 700 !important;
  }
  .ms {
    margin-left: 10px !important;
  }
  .navbar .logo {
    margin-top: 10px;
    width: 160px !important;
  }
  .CarouselHome p {
    font-size: 15px !important;
  }
  .HomeBusiness {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 50px 0;
  }
  .HomeBusiness .quote {
    font-size: 40px;
    font-weight: 200;
    font-family: "Roboto condensed";
    font-size: 18px !important;
  }
  .HomeBusiness .quote span {
    font-weight: 900;
    color: var(--color-green);
  }
  .HomeBusiness h2 {
    line-height: normal !important;
    color: var(--color-green);
    font-size: 35px;
  }
  .HomeBusiness p {
    font-size: 16px !important;
  }
  .HomeBusiness button {
    padding: 4px 11px !important;
    font-size: 12px !important;
  }
  .HomeBusiness img {
    width: 100%;
  }
  .Why .header h2 {
    font-size: 22px !important;
  }
  .Why .header p {
    font-size: 14px !important;
  }
}
@keyframes bn13bouncy {
  0% {
    top: 0em;
  }
  40% {
    top: 0em;
  }
  43% {
    top: -0.9em;
  }
  46% {
    top: 0em;
  }
  48% {
    top: -0.4em;
  }
  50% {
    top: 0em;
  }
  100% {
    top: 0em;
  }
}/*# sourceMappingURL=style.css.map */