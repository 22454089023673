:root {
    --color-primary: #000;
    ;
    --color-green: #059669;
    --color-white: #FFF;
    --color-bg: #0f0f3e;
    --color-primarys: #6f6af8;
    --color-greens: #00c476;
    --color-primary-light: hsl(242, 91%, 69%, 18%);
    --color-primary-variant: #5854c7;
    --transition: all 300ms ease;
    --card-border-radius-1: 0.3rem;
    --card-border-radius-2: 0.5rem;
    --card-border-radius-3: 0.8rem;
    --card-border-radius-4: 2rem;
    --card-border-radius-5: 5rem;
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

// ================================================================
// GENERAL
// ================================================================

* {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    appearance: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

// ================================================================


.margin50 {
    margin: 50px 0;
}

.padding50 {
    padding: 40px 0;
}

.f20 {
    font-size: 20px;
}

.ms {
    margin-left: 35px;
}

.line1 {
    border-bottom: 6px solid var(--color-green);
    width: 100px;
    margin-bottom: 10px;
}


.borderTopWhite {
    border-top: 2px solid var(--color-white);
}

.line1- {
    border-bottom: 3px dashed var(--color-green);
    width: 100px;
    margin-bottom: 4px;
}

.line2 {
    border-bottom: 6px solid var(--color-primary);
    width: 100px;
    margin-bottom: 10px;
}

.green {
    color: var(--color-green);
}

.btnsGreen {
    display: inline-block;
    padding: 0.45em 1em;
    border: 1px solid var(--color-green);
    // margin: 0 0.3em 0.3em 0;
    border-radius: 0.22em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: var(--color-green);
    text-align: center;
    transition: all 0.2s;
    animation: bn13bouncy 5s infinite linear;
    position: relative;

    &:hover {
        background-color: var(--color-green);
        color: var(--color-white);
    }

}

.btnsWhite {
    display: inline-block;
    padding: 0.45em 1em;
    border: 1px solid var(--color-white);
    // margin: 0 0.3em 0.3em 0;
    border-radius: 0.22em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: var(--color-white);
    text-align: center;
    transition: all 0.2s;
    animation: bn13bouncy 5s infinite linear;
    position: relative;

    &:hover {
        background-color: var(--color-primary);
        color: var(--color-green);
        border: none;
    }

}

.btnsDark {
    display: inline-block;
    padding: 0.45em 1em;
    // border: 1px solid var(--color-primary);
    // margin: 0 0.3em 0.3em 0;
    border-radius: 0.22em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: var(--color-primary);
    text-align: center;
    transition: all 0.2s;
    position: relative;
    background-color: var(--color-green);

    &:hover {
        color: var(--color-green);
        border: 1px solid var(--color-primary) !important;
        background-color: var(--color-primary);
        border: none;
    }

}

.btnsDeepGreen {
    display: inline-block;
    padding: 0.45em 1em;
    // margin: 0 0.3em 0.3em 0;
    border-radius: 0.35em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    background-color: var(--color-green);
    color: var(--color-white);
    text-align: center;
    transition: all 0.2s;
    animation: bn13bouncy 5s infinite linear;
    position: relative;

    &:hover {
        background-color: var(--color-greens);
        color: var(--color-white);
    }

}

.Getstartedmodal {
    h1 {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 20px;
        color: var(--color-green);
    }

    .diff {
        padding-top: 10px;

        button {
            font-weight: 600;
        }
    }
}

.LinkNav {
    background-color: var(--color-green);
    color: var(--color-white);
    padding: 5px 20px;

    ul {
        display: flex;
        align-items: center;
        margin: 0px;

        li {
            margin: 10px;
        }
    }
}

.border1 {
    border-right: 3px solid var(--color-green);
    border-bottom: 3px solid var(--color-green);
}

.border3 {
    border-right: 3px solid var(--color-green);
}

.border2 {
    border-left: 3px solid var(--color-green);
    border-bottom: 3px solid var(--color-green);
}

/////BUTTONS
/// 
.ChangeButton {
    position: relative;
    padding: 1rem 2rem 0.5rem 2.5rem;
    color: #000000;
    border: 3px solid #776e62;
    -webkit-transition: padding 0.3s ease-in-out;
    transition: padding 0.3s ease-in-out;
}

.ChangeButton:before {
    content: "";
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border-right: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.ChangeButton:hover {
    padding: 0.75rem 2.25rem;
}

.ChangeButton:hover:before {
    top: 0;
    left: 0;
}

/// 

.upper,
.down,
.side {
    padding: 10px 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    margin: 10px;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
}

.upper {
    border: 2px solid rgb(255, 255, 255);
    z-index: 1;
    color: white;
}

.upper:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgb(255, 255, 255);
    transition: all 0.3s ease;
}

.upper:hover {
    color: var(--color-green);
}

.upper:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
}

.Networkdiv {
    width: 150px;
    border-radius: 10px;
    background-color: #fff;
    height: 85px;
    margin: 10px;
    padding: 15px;
    object-position: center;
    object-fit: cover;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    img {
        max-height: 60px;
    }
}

.side {
    border: 2px solid rgb(255, 255, 255);
    z-index: 1;
    color: white;
}

.side:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    background: rgb(255, 255, 255);
    transition: all 0.3s ease;
}

.side:hover {
    color: var(--color-green);
}

.side:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}

.side:active {
    top: 2px;
}

.down {
    border: 2px solid rgb(255, 255, 255);
    z-index: 1;
    color: white;
}

.down:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgb(255, 255, 255);
    transition: all 0.3s ease;
}

.down:hover {
    color: var(--color-green);
}

.down:hover:after {
    top: 0;
    height: 100%;
}

.down:active {
    top: 2px;
}

// ================================================================
// HOME
// ================================================================
.Banner1 {
    margin: 60px 0;

    h1 {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        font-size: 65px;

        span {
            font-weight: 400;
        }
    }

    p {
        font-size: 20px;

        span {
            color: var(--color-green);
        }
    }

    img {
        width: 100%;
    }

    .ms {
        margin: 0 !important;
    }
}

.Scale {
    background-color: var(--color-primary);
    padding: 30px 0;

    h1 {
        color: var(--color-green);
        font-size: 40px;
    }

    p {
        color: var(--color-white);
        font-size: 20px;
    }
}

.Why {
    margin: 50px 0;

    .header {
        h2 {
            line-height: normal;
            font-size: 30px;
            color: var(--color-green);
        }

        p {
            font-size: 20px;
        }
    }
}

.Tab {
    margin: 40px 0;

    h2 {
        line-height: normal;
    }

    .tab {
        border-top: 1px solid #ddd;
        padding-top: 35px;
        cursor: pointer;

        button {
            border: 2px solid #ddd;
            border-radius: 10px;
            padding: 3px 10px;
            font-size: 13px;
            display: none;
        }

        &:hover {
            border-top: 1px solid var(--color-green);

            button {
                display: block;

                &:hover {
                    background-color: var(--color-green);
                    color: var(--color-white);
                    border: 2px solid var(--color-green);
                }
            }
        }
    }

    img {
        width: 100%;
    }
}

//
.HomeBusiness {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 50px 0;

    .quote {
        font-size: 40px;
        font-weight: 200;
        font-family: 'Roboto condensed';

        span {
            font-weight: 900;
            color: var(--color-green);
        }
    }

    h2 {
        line-height: 60px;
        color: var(--color-green);
        font-size: 60px;
    }

    p {
        font-size: 27px;
    }

    button {
        border: 2px solid #ddd;
        border-radius: 10px;
        padding: 5px 15px;
        font-size: 16px;

        &:hover {
            background-color: var(--color-green);
            color: var(--color-white);
            border: 2px solid var(--color-green);
        }
    }

    img {
        width: 100%;
    }
}

.InviteToNetwork {
    background-color: var(--color-primary);
    padding: 50px 0;

    .left {
        p {
            font-size: 15px;
            margin: 0;
        }

        padding: 25px;
    }

    .right {
        p {
            font-size: 20px;
            margin: 0;
            color: var(--color-white);
        }

        padding: 20px 0;
    }
}

.CarouselHome {
    // height: 80vh;

    .carousel div {
        box-shadow: none !important;
        color: var(--color-primary);
    }

    .carousel-inner {
        background: #F4FCFA;

        .container {
            height: 400px;
            padding: 50px 0;
        }

        .pt-0 {
            color: #07101980;
        }

        .col-md-10 {
            img {
                width: 40px;
            }

            p {
                font-size: 22px;
                font-weight: 500;
            }
        }

        img {
            width: 50px;
        }
    }

}

.Visitblog {
    padding: 50px 0;

    .row {
        border-bottom: 1px solid #ddd;
    }

    h1 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .imgdiv {
        position: relative;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 10px;
        }

        span {
            position: absolute;
            bottom: 15px;
            left: 15px;
            padding: 5px 10px;
            font-size: 13px;
            border-radius: 10px;
            background-color: var(--color-white);
        }
    }


}

.PathfinderHome {
    padding: 30px 0;

    h2 {
        line-height: normal;
        font-size: 40px;
        color: var(--color-green);
    }

    img {
        width: 150px;
        border-radius: 50%;
        object-fit: cover;
        height: 150px;
    }

    .text {
        p {
            font-size: 15px;
        }
    }
}

.parners {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    div {
        margin: 0 25px;
    }
}


// ================================================================
// NEW FOOTER
// ================================================================

.NewFooter {
    background-color: var(--color-primary);
    padding-top: 50px;
    padding-bottom: 10px;
    color: var(--color-white);

    img {
        width: 250px;
    }

    .join {
        font-size: 20px;
        margin: 15px 0;
    }

    .social {
        display: flex;
        align-items: center;

        svg {
            margin: 0 10px;
            color: var(--color-green);
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 10px;
        }
    }

    span {
        color: var(--color-green);
        font-size: 12px;
    }
}

// ================================================================
// VALUES
// ================================================================
.values {
    h1 {
        font-size: 30px;
        font-family: 'Bebas Neue', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color: var(--color-green);
    }

    .cardd {
        cursor: pointer;
        position: relative;
        // img{

        // }
        transition: var(--transition);

        &:hover {
            p {
                transition: var(--transition);
                display: block !important;
            }
        }

        .text {
            position: absolute;
            background-image: linear-gradient(#000000c9, #000000f9);
            bottom: 0px;
            padding: 15px;
            width: 100%;

            .flex {
                display: flex;
                align-items: center;
                justify-content: space-between;

                svg {
                    color: var(--color-green);
                }
            }

            h2 {
                line-height: normal;
                font-size: 25px;
                margin-bottom: 5px;
                color: var(--color-green);
            }

            p {
                color: var(--color-white);
                display: none;
            }
        }
    }

}

// ================================================================
// SLIDER
// ================================================================

.carousel {
    // background-color: var(--color-green);
    color: var(--color-white);
    padding: 5px;
    border-radius: var(--card-border-radius-2);

    div {
        border-radius: var(--card-border-radius-2);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    }

    h2 {
        // text-align: center;
        line-height: normal;
        font-size: 20px !important;
        background-color: var(--color-primary);
        padding: 10px;
        border-radius: var(--card-border-radius-2) var(--card-border-radius-2) 0 0;
        font-weight: 700 !important;
    }

    p {
        padding: 15px;
    }
}

// ================================================================
// NAVBAR
// ================================================================
nav {
    border-bottom: none !important;
}

.navbar {
    background-color: var(--color-white);
    border-bottom: none !important;
    padding: 10px;
    border: none !important;
    --bs-navbar-toggler-border-color: none !important;

    .logo {
        width: 200px;
    }

    span {
        color: var(--color-primary);
        margin: 5px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        &:hover {
            color: var(--color-green);
        }
    }

    li {
        font-size: 17px;
        margin: 8px 0;
    }

    .dropdown-menu {
        padding: 0 !important;
        width: 200px;
        margin: 0 !important;
        background: #efefef !important;
        border: none;
        border-radius: var(--card-border-radius-2);

        .dropdown-item {
            padding: 10px 20px !important;
            margin: 0 !important;

            &:hover {
                background-color: #06855d !important;
                color: var(--color-white) !important;
                transition: var(--transition);
            }
        }
    }

}

// ================================================================
// SOLUTIONS
// ================================================================

.banner1 {
    background-image: url(../assets/images/solutions/pexels-michelangelo-buonarroti-8728388.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;

    .text {
        position: absolute;
        left: 7%;
        width: 500px;
        bottom: 35%;


        h2 {
            font-size: 45px;
            line-height: 46px;
            color: var(--color-primary);
            font-weight: 600;
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .arrow {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 15%;
    }
}

.banner2 {
    background-image: url(../assets/images/solutions/pexels-google-deepmind-17485678.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    .text {
        position: absolute;
        right: 3%;
        width: 500px;
        // text-align: right;
        bottom: 35%;

        h2 {
            font-size: 45px;
            line-height: 46px;
            color: var(--color-primary);
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
        }
    }

    .arrow {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 10%;
    }
}

.banner3 {
    background-image: url(../assets/images/solutions/pexels-google-deepmind-17483868.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    .text {
        position: absolute;
        left: 5%;
        width: 400px;
        top: 12%;

        h2 {
            font-size: 40px;
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            line-height: 40px;
        }
    }

    .arrow {
        position: absolute;
        left: 50%;
        bottom: 15%;

        svg {
            color: var(--color-primary);
        }
    }
}

.banner1 .arrow img,
.banner2 .arrow img,
.banner3 .arrow img {
    height: 56px;
    cursor: pointer;
}

.section1 {
    h2 {
        font-size: 35px;
        line-height: 40px;
    }
}

.section2 {
    background-color: var(--color-primary);
    padding: 30px 0;

    h2 {
        font-size: 30px;
        color: var(--color-green);
        line-height: normal;
    }

    img {
        border-radius: 20px 0 20px 0;
    }

    .text {
        h2 {
            color: var(--color-green);
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
        }

        color: var(--color-white);
        padding: 10px;
        border-radius:0 0 10px 10px;
    }
}

.section3 {
    background-color: var(--color-primary);
    padding: 50px 0;

    .head {
        padding: 20px;
        background-color: var(--color-green);

        h2 {
            font-size: 25px;
            color: var(--color-white);
            line-height: normal;
        }
    }

    button {
        padding: 0 !important;
    }

    .accordion-button:not(.collapsed) {
        color: var(--color-green) !important;
        background-color: var(--color-primary);
        line-height: normal;
        box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    }

    .accordion-button:focus {
        border-color: gray;
        box-shadow: grey;
    }

    .accordion-button {
        line-height: normal;
        padding: 20px !important;
        color: var(--color-green);
        background-color: var(--color-primary);
    }

    .accordion-body {
        background-color: var(--color-green);
    }
}

.section4 {
    background-color: var(--color-green);

    .first {
        padding: 13%;
        background-image: url(../assets/images/solutions/pexels-pixabay-373543.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        h2 {
            padding: 40px;
            background-color: #00000084;
            color: var(--color-white);
            line-height: normal;
            font-size: 25px;
        }
    }

    .second {
        margin-top: 40px;
        padding: 10px;

        h2 {
            font-size: 25px;
            color: var(--color-white);
            line-height: normal;
        }
    }
}

// ================================================================
// CONTACT US
// ================================================================

.contactPage {
    .cont {
        background-color: var(--color-primary);
        color: var(--color-primary);
        padding: 30px;

    }
}

// ================================================================
// CONTACT PAGE
// ================================================================

.contact {
    h2 {
        line-height: normal;
        font-size: 30px;
        color: var(--color-green);
    }

    button {
        background-color: var(--color-green);
        color: var(--color-primary);
        padding: 10px 25px;
        border-radius: 10px;
        font-weight: 600;
        transition: var(--transition);
        cursor: pointer;

        &:hover {
            background-color: #06855d;
        }
    }
}

// ================================================================
// ABOUT US
// ================================================================
.about {
    .bannerr1 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.596), #000000d5), url(/src/assets/images/about/pexels-tara-winstead-8386422.jpg);
        height: 30vh;
        background-size: cover;
        background-position: center;
        position: relative;

        h2 {
            text-align: center;
            line-height: normal;
            font-size: 50px;
            font-weight: 700;
            // position: absolute;
            // top: 40%;
            // left: 40%;
            // padding-top: calc(350px - 100px);
            padding-top: calc(100px - 30px);
            color: var(--color-green);
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .sectionn1 {
        h2 {
            color: var(--color-green);
            font-size: 30px;
            line-height: normal;
            margin: 20px 0;
        }

        h1 {
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
            font-size: 25px;
            text-align: center;
            font-weight: 700;
            color: var(--color-white);
            padding: 5px 10px;
        }

        .who {
            background-color: var(--color-primary);

            h2 {
                color: var(--color-green);
                font-size: 20px;
            }

            p {
                color: var(--color-white);
            }
        }

        .img3 {
            background-image: url(/src/assets/images/about/pexels-sierra-koder-10264752.jpg);
            height: 100%;
            background-size: cover;
            background-position: center;
        }
    }

    .sectionn2 {
        h2 {
            color: var(--color-white);
            font-size: 35px;
            line-height: normal;

        }

        .one {
            background-color: var(--color-green);
            text-align: center;
        }

    }

    .sectionn3 {
        background-color: var(--color-primary);
        padding: 30px 0;

        .mb-5 {
            p {
                font-size: 20px;
                text-align: center;
            }
        }

        .opp {
            color: var(--color-green);
            font-size: 25px;
            text-align: center;
            line-height: normal;
        }

        .list {
            h2 {
                color: var(--color-green);
                font-size: 20px;
                line-height: normal;
                margin-bottom: 10px;
            }
        }

        p {
            color: var(--color-white);
        }

        .one {
            text-align: center;
        }
    }
}

// ================================================================
// JUDGE AUTH
// =====
.JudgeAuth {
    min-height: 100vh;

    .login {
        border: 1px solid #ddd;
        // border-radius: 15px;
        background-color: #efefef;

        .form-check {
            display: flex;
            align-items: center;
        }
    }
}

// ================================================================
// APPLY 
// =====
.Apply {
    min-height: 100vh;

    .Header {
        background-color: var(--color-green);
        padding: 15px;
        color: white;
        margin-bottom: 30px;
    }

    .judgeSide {
        border: 1px solid #ddd;
        border-radius: 10px;
        width: 100%;

        .span {
            padding: 15px;
            background: #efefef;
            width: 100%;
            border-radius: 10px 10px 0 0;
            border: none;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                padding: 15px;
                // margin: 5px 0;
                display: flex;
                align-items: center;
                border-top: 1px solid #efefef;

                svg {
                    margin-right: 10px;
                }

                &:hover {
                    background-color: #efefef;
                    cursor: pointer;
                }
            }
        }
    }
}

// ================================================================
// STAY CONNECTED PAGE
// =====
.connected {
    h2 {
        color: var(--color-green);
        font-size: 35px;
        line-height: normal;
        margin-bottom: 10px;
    }
}

.connected2 {
    background-color: var(--color-green);
    border-radius: 30px;
    margin: 50px 0;
    color: var(--color-white);
    padding: 20px;
    h2 {
        color: var(--color-white);
        font-size: 35px;
        line-height: normal;
        margin-bottom: 10px;
    }
}

// ================================================================
// PATHFINDERS PAGE
// ================================================================
.pathfinders {
    .bannerr1 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.596), #000000d5), url(/src/assets/images/pathfinder/pexels-kampus-production-5940841.jpg);
        height: 35vh;
        background-size: cover;
        background-position: center;
        position: relative;

        p {
            text-align: center;
            color: var(--color-white);
            font-size: 20px !important;
        }

        span {
            // background-color: var(--color-green);
            padding: 0 10px;
            color: var(--color-white);
        }

        h2 {
            text-align: center;
            line-height: normal;
            font-size: 50px;
            font-weight: 700;
            // position: absolute;
            // top: 40%;
            // left: 40%;
            // padding-top: calc(350px - 100px);
            padding-top: calc(100px - 40px);
            color: var(--color-green);
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .section11 {
        h2 {
            color: var(--color-green);
            font-size: 30px;
            line-height: normal;
        }
    }

    .section12 {
        background-color: var(--color-green);
        padding: 30px 0;

        .text {
            text-align: center;
        }

        h2 {
            color: var(--color-white);
            font-size: 30px;
            line-height: normal;
            text-align: center;
        }

        .apply {
            display: inline-block;
            padding: 0.65em 1.6em;
            border: 3px solid #ffffff;
            margin: 15px 0;
            border-radius: 0.12em;
            box-sizing: border-box;
            text-decoration: none;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            transition: all 0.2s;
            animation: bn13bouncy 5s infinite linear;
            position: relative;

            &:hover {
                background-color: white;
                color: #000000;
            }
        }
    }

    .section13 {
        padding: 40px 0;

        .text {
            text-align: center;

            h2 {
                color: var(--color-green);
                font-size: 30px;
                line-height: normal;
                text-align: center;
            }
        }

        h2 {
            color: var(--color-green);
            font-size: 20px;
            line-height: normal;
            margin-bottom: 5px;
        }

        .row {
            div {
                margin-bottom: 10px;
            }
        }

        .apply {
            display: inline-block;
            padding: 0.65em 1.6em;
            border: 3px solid var(--color-green);
            margin: 15px 0;
            border-radius: 0.12em;
            box-sizing: border-box;
            text-decoration: none;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            color: var(--color-green);
            text-align: center;
            transition: all 0.2s;
            animation: bn13bouncy 5s infinite linear;
            position: relative;

            &:hover {
                background-color: var(--color-green);
                color: #000000;
            }
        }
    }

    .section14 {
        background-color: var(--color-primary);
        padding: 40px 0;
        color: var(--color-white);

        .accordion-button:not(.collapsed) {
            color: var(--color-green) !important;
            background-color: var(--color-primary);
            line-height: normal;
            box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
        }

        span {
            color: var(--color-green);
            font-weight: 600;
        }

        .accordion-button:focus {
            border-color: gray;
            box-shadow: grey;
        }

        .accordion-button {
            line-height: normal;
            padding: 20px !important;
            color: var(--color-green);
            background-color: var(--color-primary);
        }

        .accordion-body {
            background-color: var(--color-green);

            p {
                color: var(--color-white);
                font-weight: 600;
                margin-bottom: 10px;
            }

            ul {
                li {
                    list-style: inside
                }
            }
        }

        h2 {
            color: var(--color-green);
            font-size: 20px;
            line-height: normal;
        }

        .row {
            div {
                margin-bottom: 10px;
            }
        }

        .apply {
            display: inline-block;
            padding: 0.65em 1.6em;
            border: 3px solid var(--color-green);
            margin: 15px 0;
            border-radius: 0.12em;
            box-sizing: border-box;
            text-decoration: none;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            color: var(--color-green);
            text-align: center;
            transition: all 0.2s;
            animation: bn13bouncy 5s infinite linear;
            position: relative;

            &:hover {
                background-color: var(--color-green);
                color: #000000;
            }
        }
    }
}


.judgebanner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.684), #000000d5), url(/src/assets/images/judge/pexels-towfiqu-barbhuiya-3440682-11505601.jpg);
    height: 30vh;
    background-size: cover;
    background-position: center;
    position: relative;

    p {
        text-align: center;
        color: var(--color-white);
        font-size: 20px !important;
    }

    span {
        padding: 0 10px;
        color: var(--color-white);
    }

    h2 {
        text-align: center;
        line-height: normal;
        font-size: 50px;
        font-weight: 700;
        padding-top: calc(100px - 40px);
        color: var(--color-green);
        font-family: 'Bebas Neue', sans-serif;
        font-family: 'Montserrat', sans-serif;
    }
}

.greencard {
    background-color: #00000012;
    padding: 20px;

    h2 {
        font-size: 25px !important;
    }
}

.awardsection {
    h2 {
        font-size: 30px;
        color: var(--color-green);
    }

    img {
        width: 100%;
        object-fit: cover;
    }
}

.white {
    color: white;
}

// ================================================================
// PARTNERS PAGE
// ================================================================

.partners {
    .bannerr1 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.684), #000000d5), url(/src/assets/images/partners/pexels-ketut-subiyanto-4963436.jpg);
        height: 30vh;
        background-size: cover;
        background-position: center;
        position: relative;

        p {
            text-align: center;
            color: var(--color-white);
            font-size: 20px !important;
        }

        span {
            padding: 0 10px;
            color: var(--color-white);
        }

        h2 {
            text-align: center;
            line-height: normal;
            font-size: 50px;
            font-weight: 700;
            padding-top: calc(100px - 40px);
            color: var(--color-green);
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .secttion1 {
        h2 {
            color: var(--color-green);
            font-size: 30px;
            line-height: normal;
        }
    }

    .secttion2 {
        h1 {
            color: var(--color-green);
            font-size: 30px;
            text-align: center;
            font-weight: 600;
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }

        .text {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            span {
                margin: 10px 0;

                img {
                    width: 50%;
                    margin-left: 40px;
                }
            }

            h2 {
                color: var(--color-green);
                font-size: 22px;
                line-height: normal;
            }

        }
    }

    .secttion3 {
        background-color: var(--color-primary);
        color: var(--color-white);
        padding: 30px 0;

        h1 {
            color: var(--color-green);
            font-size: 30px;
            text-align: center;
            font-weight: 600;
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }

        p {
            font-size: 20px;
            text-align: center;
        }

        .type1,
        .type2,
        .type3,
        .type4,
        .type5 {
            border-radius: var(--card-border-radius-2);
            margin-bottom: 10px;

            h2 {
                font-size: 22px;
                color: var(--color-white);
                line-height: normal;
                padding: 10px 20px;
                border-radius: var(--card-border-radius-2) var(--card-border-radius-2) 0 0;
                background-color: var(--color-green);
            }

            p {
                padding: 15px;
                text-align: left;
                color: var(--color-white);
            }
        }

        .type1 {
            color: var(--color-primarys);
            border: 2px solid var(--color-primarys);

            h2 {
                background-color: var(--color-primarys);
            }
        }

        .type2 {
            color: var(--color-greens);
            border: 2px solid var(--color-greens);

            h2 {
                background-color: var(--color-greens);
            }
        }

        .type3 {
            color: #f5dd42;
            border: 2px solid #f5dd42;

            h2 {
                background-color: #f5dd42;
            }
        }

        .type4 {
            color: #f56942;
            border: 2px solid #f56942;

            h2 {
                background-color: #f56942;
            }
        }

        .type5 {
            color: #42eff5;
            border: 2px solid #42eff5;

            h2 {
                background-color: #42eff5;
            }
        }
    }

    .secttion4 {
        background-image: linear-gradient(#000000a2, #000000ad), url(../assets/images/partners/img.png);
        background-position: center;
        background-size: contain;
        padding: 40px;
        height: 50vh;

        .text {
            background-color: #0596688a;
            padding: 20px;
            color: white;

            p {
                font-size: 25px;
                font-family: 'Oswald', sans-serif;
            }
        }
    }

    .secttion5 {
        background-color: var(--color-green);

        .first {
            padding: 8%;
            background-image: url(../assets/images/partners/pexels-kindel-media-7688156.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            h2 {
                padding: 25px;
                background-color: #00000084;
                color: var(--color-white);
                line-height: normal;
                font-size: 22px;
            }
        }

        .second {
            margin-top: 40px;
            padding: 10px;

            h2 {
                font-size: 25px;
                color: var(--color-white);
                line-height: normal;
            }
        }

        .apply {
            display: inline-block;
            padding: 0.65em 1.6em;
            border: 3px solid var(--color-primary);
            margin: 15px 0;
            border-radius: 0.12em;
            box-sizing: border-box;
            text-decoration: none;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            color: var(--color-primary);
            text-align: center;
            transition: all 0.2s;
            animation: bn13bouncy 5s infinite linear;
            position: relative;

            &:hover {
                background-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }
}


// ================================================================
// SECURITY PAGE
// ================================================================
.security {
    .bannerr1 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.596), #000000d5), url(/src/assets/images/security/pexels-pixabay-60504.jpg);
        height: 35vh;
        background-size: cover;
        background-position: center;
        position: relative;

        p {
            text-align: center;
            color: var(--color-white);
            font-size: 20px !important;
        }

        span {
            padding: 0 10px;
            color: var(--color-white);
        }

        h2 {
            text-align: center;
            line-height: normal;
            font-size: 50px;
            font-weight: 700;
            padding-top: calc(100px - 40px);
            color: var(--color-green);
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .sectioon1 {
        .path {
            font-size: 20px;
            text-align: left;
        }

        .text {
            padding: 20px;

            h2 {
                line-height: normal;
                font-size: 18px;
                color: var(--color-greens);
            }

            h1 {
                font-family: 'Oswald', sans-serif;
                font-size: 25px;
            }

            ul {
                margin-bottom: 25px;

                li {
                    margin-bottom: 10px;

                    p {
                        margin-left: 15px;
                    }
                }
            }
        }

        .role {
            h2 {
                line-height: normal;
                font-size: 23px;
                margin-bottom: 10px;
                color: var(--color-greens);
            }

            ul {
                li {
                    list-style: inside;
                }
            }
        }

        .tab {
            background-color: var(--color-primary);
            padding: 30px 0;
            color: var(--color-white);

            h2 {
                line-height: normal;
                font-size: 23px;
                margin-bottom: 10px;
                color: var(--color-greens);
            }
        }
    }

    .sectioon2 {
        background-color: var(--color-green);

        .first {
            padding: 8%;
            background-image: url(../assets/images/security/pexels-jerome-dominici-612266.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            h2 {
                color: var(--color-primary);
                line-height: normal;
                font-size: 35px;
                margin-left: 10px;
            }

            .light {
                padding: 25px;
                background-color: #fbf9f984;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                img {
                    width: 50px;
                }
            }

        }

        .second {
            margin-top: 20px;
            padding: 10px;

            h2 {
                font-size: 25px;
                color: var(--color-white);
                line-height: normal;
            }
        }

        .apply {
            display: inline-block;
            padding: 0.65em 1.6em;
            border: 3px solid var(--color-primary);
            margin: 15px 0;
            border-radius: 0.12em;
            box-sizing: border-box;
            text-decoration: none;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            color: var(--color-primary);
            text-align: center;
            transition: all 0.2s;
            animation: bn13bouncy 5s infinite linear;
            position: relative;

            &:hover {
                background-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }
}

// ================================================================
// FAQS PAGE
// ================================================================
.faqs {
    .bannerr1 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.713), #000000d5), url(/src/assets/images/faqs/pexels-leeloo-thefirst-5428829.jpg);
        height: 35vh;
        background-size: cover;
        background-position: center;
        position: relative;

        p {
            text-align: center;
            color: var(--color-white);
            font-size: 20px !important;
        }

        span {
            padding: 0 10px;
            color: var(--color-white);
        }

        h2 {
            text-align: center;
            line-height: normal;
            font-size: 50px;
            font-weight: 700;
            padding-top: calc(100px - 30px);
            color: var(--color-green);
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .ssection1 {
        h2 {
            line-height: normal;
            font-size: 30px;
            margin-bottom: 10px;
            color: var(--color-green);
        }
    }

    .accordion-button:not(.collapsed) {
        color: var(--color-green) !important;
        // background-color: var(--color-primary);
        line-height: normal;
        box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 white;
    }

    .accordion-button:focus {
        border-color: gray;
        box-shadow: grey;
    }

    .accordion-header {
        margin: 0 !important;
    }

    .accordion-button {
        line-height: normal;
        padding: 20px !important;
        color: var(--color-green);
        // background-color: var(--color-primary);
    }

    .accordion-body {
        background-color: #efefef5f;

        p {
            color: var(--color-primary);
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: white !important;
    }

    .ssection3 {
        background-color: var(--color-green);
        padding: 20px 0;

        h1 {
            font-size: 40px;
            color: var(--color-white);
            font-weight: 700;
        }
    }
}


// ================================================================
// Get IN TOUCH PAGE
// ================================================================
.getintouch {
    .ready {
        p {
            font-size: 20px;
            margin-bottom: 30px;
        }
    }

    .bannerr1 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.713), #000000d5), url(/src/assets/images/Get-in-touch/pexels-adrienn-1458283.jpg);
        height: 40vh;
        background-size: cover;
        background-position: center;
        position: relative;

        p {
            text-align: center;
            color: var(--color-white);
            font-size: 20px !important;
        }

        span {
            padding: 0 10px;
            color: var(--color-white);
        }

        h2 {
            text-align: center;
            line-height: normal;
            font-size: 50px;
            font-weight: 700;
            padding-top: calc(100px - 40px);
            color: var(--color-green);
            font-family: 'Bebas Neue', sans-serif;
            font-family: 'Montserrat', sans-serif;
        }

        .flex {
            margin-top: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .seection1 {
        h2 {
            line-height: normal;
            font-size: 30px;
            margin-bottom: 20px;
            color: var(--color-green);
            text-align: center;
        }

        .form-check-label {
            font-size: 14px;

            span {
                color: blue;
            }
        }
    }

    .seection2 {
        background-color: var(--color-primary);
        padding: 30px 0;

        h2 {
            line-height: normal;
            font-size: 30px;
            margin-bottom: 20px;
            color: var(--color-green);
            text-align: center;
        }

        p,
        h1 {
            color: var(--color-white);
        }

        .social {
            margin: 20px 0;

            h1 {
                font-size: 20px;
            }

            .innit {
                margin: 10px 0;

                svg {
                    border-radius: 50%;
                    // background-color: rgba(255, 255, 255, 0.635);
                    width: 100px;
                    color: var(--color-green);
                    padding: 15px;
                    height: 100px;
                }
            }
        }
    }

    .seection3 {
        background-color: var(--color-primary);
        padding: 30px 0;

        h2 {
            line-height: normal;
            font-size: 30px;
            margin-bottom: 20px;
            color: var(--color-green);
        }

        p {
            color: var(--color-green);
        }

        button {
            background-color: var(--color-green);
            border-radius: 5px;
            padding: 7px 25px;
            margin: 20px 0;

            svg {
                color: var(--color-primary);
            }
        }

    }

    .cont {
        background-color: var(--color-green);
        padding: 30px 0;
        color: var(--color-primary);

        h2 {
            line-height: normal;
            font-size: 40px;
            margin-bottom: 10px;
            color: var(--color-primary);
        }

        button {
            display: inline-block;
            padding: 0.45em 1em;
            border: 1px solid var(--color-white);
            // margin: 0 0.3em 0.3em 0;
            border-radius: 0.22em;
            box-sizing: border-box;
            text-decoration: none;
            font-family: "Roboto", sans-serif;
            font-weight: 600;
            color: var(--color-white);
            text-align: center;
            transition: all 0.2s;
            animation: bn13bouncy 5s infinite linear;
            position: relative;

            &:hover {
                background-color: var(--color-primary);
                color: var(--color-green);
                border: none;
            }
        }

        .ms {
            margin: 0 !important;
        }

        // .sign{
        //     text-align: right;
        // }
        .heard {
            text-align: right;
            padding-top: 30px;
        }
    }

    .seection4 {
        padding: 20px 0;
        background-color: var(--color-primary);
        color: var(--color-white);

        .thank {
            h2 {
                line-height: normal;
                font-size: 30px;
                margin-bottom: 10px;
                color: var(--color-green);
            }

            p {
                font-size: 20px;
            }
        }
    }
}

// ================================================================
// EVENTS PAGE
// ================================================================
.eventPage {
  .events{
    border: 1px solid rgba(224, 224, 224, .7);
    background: #f8f8f8;
    border-radius: 30px;
    ul{
        background-color: var(--color-green);
        color: white;
        font-size: 16px;
        font-weight: 600;
        li{
            display: flex;
            align-items: center;
        }
    }
    p{
        font-size: 16px;
    }
    img{
        width: 100%;
        border-radius: 30px;
    }
    svg{
        margin-right: 10px;
    }
  }
  .highlights{
    li{
        margin: 20px 0;
        span{
            font-size: 30px;
            font-weight: 800;
        }
    }
  }
}

// ================================================================
//MEDIA QUERY CONTROL
// ================================================================
// 
@media only screen and (max-width: 600px) {

    .padding50 {
        padding: 45px 0 !important;
    }

    .Banner1 {
        margin: 30px 0 !important;

        h1 {
            font-size: 35px !important;
        }

        p {
            font-size: 14px !important;
        }
    }

    .banner1,
    .banner2,
    .banner3 {
        .text {
            width: 290px !important;

            h2 {
                font-size: 27px !important;
            }
        }
    }

    .LinkNav {
        padding: 5px 15px !important;

        ul {
            display: flex;
            align-items: center;
            padding: 0px !important;

            li {
                margin: 10px;
                font-size: 15px !important;
            }
        }
    }

    .getintouch {
        .flex {
            margin-top: 10px !important;
        }
    }

    // .banner2 {
    //     .text {
    //         right: -10% !important;
    //     }
    // }

    .contact {
        .Contactimage {
            display: none !important;
        }
    }

    .pathfinders {
        .bannerr1 {
            background-image: linear-gradient(rgba(0, 0, 0, 0.596), #000000d5), url(/src/assets/images/pathfinder/pexels-kampus-production-5940841.jpg);
            height: 35vh;
            background-size: cover;
            background-position: center;
            position: relative;

            p {
                font-size: 15px !important;
            }

            h2 {
                text-align: center;
                line-height: normal;
                font-size: 30px !important;
                font-weight: 700 !important;
            }
        }
    }

    .ms {
        margin-left: 10px !important;
    }

    .navbar {
        .logo {
            margin-top: 10px;
            width: 160px !important;
        }

    }

    .CarouselHome {
        p {
            font-size: 15px !important;
        }
    }

    .HomeBusiness {
        background-color: var(--color-primary);
        color: var(--color-white);
        padding: 50px 0;

        .quote {
            font-size: 40px;
            font-weight: 200;
            font-family: 'Roboto condensed';
            font-size: 18px !important;

            span {
                font-weight: 900;
                color: var(--color-green);
            }
        }

        h2 {
            line-height: normal !important;
            color: var(--color-green);
            font-size: 35px;
        }

        p {
            font-size: 16px !important;
        }

        button {
            padding: 4px 11px !important;
            font-size: 12px !important;
        }

        img {
            width: 100%;
        }
    }

    .Why {

        .header {
            h2 {
                font-size: 22px !important;
            }

            p {
                font-size: 14px !important;
            }
        }
    }
}

@keyframes bn13bouncy {
    0% {
        top: 0em;
    }

    40% {
        top: 0em;
    }

    43% {
        top: -0.9em;
    }

    46% {
        top: 0em;
    }

    48% {
        top: -0.4em;
    }

    50% {
        top: 0em;
    }

    100% {
        top: 0em;
    }
}