@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  color: #000;
  overflow-x: hidden;

}

/* REACT CAROUSEL  */
.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled{
    background-color: #000 !important;
    box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.416) !important;
}

.rec.rec-arrow-left:active,
.rec.rec-arrow-right:active{
    background-color: #000 !important;
    box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.416) !important;
}

.rec.rec-arrow-left:focus,
.rec.rec-arrow-right:focus{
    background-color: #000 !important;
    box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.416) !important;
}

button.rec-dot{
  background-color: #024b34;
  box-shadow: 0 0 1px 3px #000;
}

button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
  box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.416);
}

button.rec-dot:active  {
  box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.416);
}

/* ====== Mobile Nav styles ======= */
.icon {
  border: 0;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.header-menu .menu {
  display: flex;
  align-items: center;
  padding: 0;
  gap: 30px;
}


@media (max-width: 767px) {
  .icon {
    display: block;
  }

  .header-menu.active .menu {
    right: 0;
    display: block;
  }

  .header-menu .menu {
    display: none;
    background-color: #FFFFFF;
    position: absolute;
    z-index: 2;
    top: 4.28rem;
    right: -100%;
    flex-direction: column;
    padding: 20% 0;
    width: 65%;
    height: 92vh;
    opacity: 1;
    transition: all 0.8s ease-in-out;
    gap: 40px;
  }

  /* .header-menu li {
    font-size: 20px;
  } */

  .header-menu ul button {
    margin: 1.5rem 0;
  }

  .header-link {
    display: block;
    font-size: 20px;
    font-family: 'Roboto Condensed';
    text-align: center;
    margin: 20px 0;
  }

  .action-btn {
    flex-direction: column;
    /* background-color: #151515; */
    background-color: #FFFFFF;
  }

  .c-header-btn {
    display: block !important;
    margin: 0 auto;
  }
}

/* ======= End of Mobile Nav styles ======= */

@media screen and (min-width: 700px) {

  .hero-container {
    background-size: cover;
}

}

@media screen and (max-width: 700px) {
  .hero-container {
    background-size: contain;
  }
}

@media screen and (max-width: 576px) {
  .hero-container {
    min-height: 300px !important;
    margin-top: 2.5rem !important;
  }
}

/* ======= React Slick ======== */

.circle-left-arrow {
  position: absolute;
  top: 40%;
  left: -16%;
  cursor: pointer;
}

.circle-right-arrow {
  position: absolute;
  top: 40%;
  right: -16%;
  cursor: pointer;
}

#testimonials .slick-slider,
#testimonials .slick-initialized,
#testimonials .slick-slide > div,
#testimonials .slick-track {
  background-color: green.100 !important;
}
.testimonial-card:focus-visible {
  outline: green.100 auto 1px !important;
}

#testimonials .slick-vertical .slick-slide {
    border-color: 1px solid green.100 !important;
}

#testimonials .slick-dots li button:before,
#testimonials .slick-dots li.slick-active button:before {
  color: #151515 !important;
}


@media (min-width: 768px) and (max-width: 989px) {
  .circle-left-arrow {
    left: -20%;
  }

  .circle-right-arrow {
    right: -20%;
  }

}

@media (max-width: 767px) {
  .circle-left-arrow,
  .circle-right-arrow {
    top: -21%;
  }

  .circle-left-arrow {
    right: 3rem;
    left: auto;
  }

  .circle-right-arrow {
    right: 0;
  }
}

@media (max-width: 700px) {
  .circle-left-arrow,
  .circle-right-arrow {
    top: -25%;
  }
}

@media (max-width: 576px) {
  .circle-left-arrow,
  .circle-right-arrow {
    top: -23%;
  }
}


/* ====== End of React Slick ======= */
h2 {
  font-family: 'Trocchi', serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 90px;
  color: #000;
}

/* ======= What we Offer ======= */

.card-container {
  perspective: 1000px;
}

.card {
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card:hover {
  transform: rotateY(180deg);
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.front {
  background-color: #FFFFFF;
  color: #000;
}

.back {
  background-color: #043030E5;
  color: #fff;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  transform: rotateY(180deg);
}

.card-content-img {
  width: 100%;
}

@media screen and (min-width: 767px) {
  .card-content-img {
  width: 270px;
}
}

/* ======= End of what we offer ======= */